import { Audit } from './audit.model';

export interface User extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  username: string;
  kcId: string;
  firstName: string;
  lastName: string;
  license: string;
  licenseExpiryDate: string;
  email: string;
  groups: string[] | string;
  customerEnabled: boolean;
  enabled: boolean;
  phone: number | string;
  image: string;
  busy?: boolean;
}

export class UserFilterParams {
  omni: string;
  customer?: string;
  group?: string;
  enabled?: boolean;
}

export class UserImportPreview {
  createdUsers: User[];
  deletedUsers: User[];
  updatedUsers: User[];
  invalidUsers: User[];
}
