import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private readonly NOMINATIM_API_URL = 'https://nominatim.openstreetmap.org/reverse';

  constructor(private http: HttpClient) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  getAddress(lat: number, lon: number): Observable<any> {
    const params = {
      format: 'json',
      lat: lat.toString(),
      lon: lon.toString(),
    };
    return this.http.get(this.NOMINATIM_API_URL, { params });
  }
}
