import { Audit } from './audit.model';

export interface Inspection extends Audit{
  id: number;
  deleted: boolean;
  code: string;
  asset: string;
  assetName: string;
  type: string;
  typeName: string;
  date: string;
  okComponents: Record<string, string[]>;
  lat: number;
  lng: number;
  completedDate: string;
}

export class InspectionFilterParams {
  asset?: string;
  type?: string;
  date?: string;
  status?: string
  omni?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
}

export interface ChecklistDetailData {
  inspection: Inspection;
  viewMap: boolean;
}
