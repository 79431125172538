import { Injectable } from '@angular/core';
import { SseClient } from 'ngx-sse-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateUtilService, PageableUtilService } from '@services/util';
import { Notification, NotificationFilterParams, PageableData, TableParams } from '@shared/models';
import { Observable, retry } from 'rxjs';
import { format, subMonths } from 'date-fns';
import { DATE_FORMAT } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private readonly API_URL = `/api/notif/notification`;
  private readonly eventState!: Observable<Event>;

  constructor(private httpClient: HttpClient,
              private dateUtil: DateUtilService,
              private util: PageableUtilService) {

    const streamClient = new SseClient(httpClient);
    const headers = new HttpHeaders().set('skip', 'true');

    this.eventState = streamClient.stream(`${this.API_URL}/realtime`,
      { keepAlive: true, reconnectionDelay: 3000, responseType: 'event' }, { headers })
      .pipe(
        retry({ resetOnSuccess: true }));
  }

  public getEventState(): Observable<Event> {
    return this.eventState;
  }

  public getNotifications(tableParams: TableParams, filters?: NotificationFilterParams): Observable<PageableData<Notification>> {

    // set 3 months filter for notifications
    const now = new Date();
    const fromDate = format(subMonths(now, 3), DATE_FORMAT.ISO_DATE);
    const toDate = format(now, DATE_FORMAT.ISO_DATE);
    const utcDateRange = this.dateUtil.calculateUTCDateRange(fromDate, toDate);
    if (!filters) filters = new NotificationFilterParams();
    filters.createdDateFrom = utcDateRange.startOfDayUTC;
    filters.createdDateTo = utcDateRange.endOfDayUTC;

    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<Notification>>(`${this.API_URL}`, { params });
  }

  public deleteNotification(code: string): Observable<Notification> {
    return this.httpClient.delete<Notification>(`${this.API_URL}/${code}`);
  }

  public bulkDeleteNotification(codes: string[] | null, all: boolean): Observable<{ message: string }> {
    const payload = { codes, all };
    if (all) delete payload.codes;
    return this.httpClient.post<{ message: string }>(`${this.API_URL}/delete-bulk`, payload);
  }

  public readAllNotifications(): Observable<unknown> {
    return this.httpClient.get<unknown>(`${this.API_URL}/read-all`);
  }

}
