import { Audit } from './audit.model';

export interface CustomerAccount extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  name: string;
  shorthandName: string;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  licenseStart: string;
  licenseEnd: string;
  enabled: boolean;
  assetLimit: number;
  responseTimeThreshold: number;
  healthThreshold: number;
  licenses: Licenses[];
  expirationMessage?: string;
}

export interface Licenses {
  name: string;
  start: string;
  end: string;
  assetLimit: number;
  length: number;
  description: string;
}

export class CustomerAccountFilterParams {
  omni: string;
}

export interface TemporaryAdmin {
  password: string;
  username: string;
  expiry: Date | string;
}