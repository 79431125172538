import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  public calculateUTCDateRange(fromDateISO?: string, toDateISO?: string):
    { startOfDayUTC: string | null; endOfDayUTC: string | null } {
    if (!fromDateISO && !toDateISO) {
      return { startOfDayUTC: null, endOfDayUTC: null };
    }

    // Helper to calculate the UTC start of the day
    const getStartOfDayUTC = (isoDate: string): string => {
      const date = new Date(isoDate);
      const offset = date.getTimezoneOffset() * 60 * 1000;
      date.setUTCHours(0, 0, 0, 0); // Set to start of UTC day
      return new Date((date.getTime() + offset)).toISOString();
    };

    // Helper to calculate the UTC end of the day
    const getEndOfDayUTC = (isoDate: string): string => {
      const date = new Date(isoDate);
      const offset = date.getTimezoneOffset() * 60 * 1000; // Offset in minutes
      date.setUTCHours(23, 59, 59, 999); // Set to end of UTC day
      return new Date((date.getTime() + offset)).toISOString();
    };

    const startOfDayUTC = fromDateISO ? getStartOfDayUTC(fromDateISO) : null;
    const endOfDayUTC = toDateISO ? getEndOfDayUTC(toDateISO) : null;

    return { startOfDayUTC, endOfDayUTC };
  }

}
