import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DATE_FORMAT } from '@shared/constants';
import { TableParams } from '@shared/models';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PageableUtilService  {

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public buildParams(tableParams?: TableParams | null, filters?: Record<string, any>): HttpParams {
    let params = new HttpParams();
    if (tableParams) {
      params = params.set('page', encodeURIComponent(tableParams.page));
      params = params.set('size', encodeURIComponent(tableParams.size));

      if (tableParams.sort) {
        tableParams.sort.forEach((param) => {
          params = params.append(
            'sort',
            param.key + ',' + param.value?.replace('end', '')
          );
        });
      }
    }

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        params = this.formatFilterParams(params, key, value);
      });
    }

    return params;
  }

  private formatFilterParams(params: HttpParams, key: string, value: unknown): HttpParams {
    if (value !== undefined && value !== null && value !== '') {
      switch (typeof value) {
        case 'boolean':
        case 'number': {
          params = params.set(key, value.toString());
          break;
        }
        case 'string': {
          value = value.trim();
          params = params.set(key, value as string);
          break;
        }
        default: {
          if (value instanceof Date) {
            params = params.set(key, format(value, DATE_FORMAT.ISO_DATE));
          }
          if (value instanceof Array) {
            value.forEach(value =>
              params = params.append(key, value)
            )
          }
        }
      }
    }
    return params;
  }

}
