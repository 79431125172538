import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import { PageableData, TableParams, User, UserFilterParams } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly API_URL = '/api/users/user';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly util: PageableUtilService
  ) { }

  public list(tableParams: TableParams, filters?: UserFilterParams): Observable<PageableData<User>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<User>>(`${this.API_URL}`, { params });
  }

  public get(code: string): Observable<User> {
    return this.httpClient.get<User>(`${this.API_URL}/${code}`);
  }

  public delete(code: string): Observable<User> {
    return this.httpClient.delete<User>(`${this.API_URL}/${code}`);
  }

  public getMe(): Observable<User> {
    return this.httpClient.get<User>(`${this.API_URL}/me`);
  }

  public import(file: File): Observable<User> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<User>(`${this.API_URL}/import-users`, formData);
  }

  public create(user: User): Observable<User> {
    if (user?.code) {
      return this.httpClient.patch<User>(`${this.API_URL}/${user.code}`, user);
    } else {
      return this.httpClient.post<User>(this.API_URL, user);
    }
  }

}
