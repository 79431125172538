import { Audit } from './audit.model';

export interface AssetComponent extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  name: string;
  image: string;
  symptoms: ComponentSymptom[];
}

export class AssetComponentFilterParams {
  nameContains?: string;
  omni?: string;
  types?: string[];
  codes?: string[];
}

export interface ComponentSymptom {
  name: string;
  description: string;
  critical: boolean;
}

export interface ComponentPayload {
  name: string;
  image: string;
  symptoms: ComponentSymptom[];
  types: string[];
}

export interface ComponentFormData {
  code?: string;
}
