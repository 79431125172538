import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableData, ReferenceData } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  private REF_URL = '/api/assets/reference-data';

  constructor(
    private httpClient: HttpClient,
  ) { }

  public search(filters: { group: string }): Observable<PageableData<ReferenceData>> {
    let params = new HttpParams();
    if (filters) {
      params = params.set('group', filters?.group);
    }

    return this.httpClient.get<PageableData<ReferenceData>>(`${this.REF_URL}`, { params })
  }

}
