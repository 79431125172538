import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import { AssetFilterParams, Assets, IssueStatus, PageableData, TableParams } from '@shared/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  private readonly ASSET_URL = '/api/assets/asset';
  refreshList = new Subject<boolean>();
  refresh$ = this.refreshList.asObservable();

  constructor(private readonly httpClient: HttpClient,
              private readonly util: PageableUtilService) { }

  public list(tableParams: TableParams, filters?: AssetFilterParams): Observable<PageableData<Assets>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<Assets>>(`${this.ASSET_URL}`, { params });
  }

  public get(code: string): Observable<Assets> {
    return this.httpClient.get<Assets>(`${this.ASSET_URL}/${code}`);
  }

  public delete(code: string): Observable<Assets> {
    return this.httpClient.delete<Assets>(`${this.ASSET_URL}/${code}`);
  }

  public create(asset: Assets): Observable<Assets> {
    if (asset?.code) {
      return this.httpClient.patch<Assets>(`${this.ASSET_URL}/${asset.code}`, asset);
    } else {
      return this.httpClient.post<Assets>(this.ASSET_URL, asset);
    }
  }

  public count(filters?: AssetFilterParams): Observable<IssueStatus[]> {
    const params = this.util.buildParams(null, filters);

    return this.httpClient.get<IssueStatus[]>(`${this.ASSET_URL}/count-by-status`, { params });
  }

  public generateQr(tableParams?: Partial<TableParams>, filters?: AssetFilterParams): Observable<HttpResponse<Blob>> {
    let params = this.util.buildParams(tableParams as TableParams, filters);
    params = params.delete('page');
    params = params.delete('size');

    return this.httpClient.get<Blob>(`${this.ASSET_URL}/generate-qr`,
      { observe: 'response', responseType: 'blob' as 'json', params });
  }

  public refreshListObservable(refresh = true): void {
    this.refreshList.next(refresh);
  }

  public getInoperationalAssetsCount(): Observable<number> {
    return this.httpClient.get<number>(`${this.ASSET_URL}/count-inoperational-assets`);
  }

  public import(file: File): Observable<Assets> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<Assets>(`${this.ASSET_URL}/import-assets`, formData);
  }

}
