import { NzTableSortOrder } from 'ng-zorro-antd/table';

export class TableParams {
  page = 0;
  size = 10;
  totalElements = 0;
  sort?: {
    key: string,
    value: NzTableSortOrder
  }[];
  last = false;
}

export interface PageableData<Content> {
  content: Content[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  size: number;
  number: number;
  sort: Sort;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}
export interface DropdownScrollApi {
  busy: string;
  paramProps: string;
  label: string;
  value: string,
  listProp: string;
  api?: string
}
