import { Injectable } from '@angular/core';
import { PageableUtilService } from '../util';
import { AssetFileListParams } from '@shared/models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  private FILES_URL = '/api/assets/file';

  constructor(
    private util: PageableUtilService,
    private httpClient: HttpClient
  ) {}

  public assetDirectory(filters: AssetFileListParams): Observable<string[]> {
    const params = this.util.buildParams(null, filters);

    return this.httpClient.get<string[]>(`${this.FILES_URL}/list`, { params })
  }

}
