import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { DashboardAsset, DashboardAssetFilterParams, DashboardWidget, IssueStatus, PageableData } from '@shared/models';
import { PageableUtilService } from '@services/util';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private API_URL = `/api/assets/dashboard`;
  private ASSET_URL = `/api/assets/asset`;

  constructor(private httpClient: HttpClient,
              private util: PageableUtilService) { }

  public getDashboardWidgets(): Observable<DashboardWidget> {
    return this.httpClient.get<DashboardWidget>(`${this.API_URL}`);
  }

  public getDashboardAssets(filters?: DashboardAssetFilterParams): Observable<DashboardAsset[]> {
    let params = this.util.buildParams(null, filters);
    params = params.set('dashboard', true);

    return this.httpClient.get<PageableData<DashboardAsset>>(`${this.ASSET_URL}`, { params })
      .pipe(map(pageableData => pageableData.content));
  }

  public getCountByStatus(): Observable<IssueStatus[]> {
    return this.httpClient.get<IssueStatus[]>(`${this.ASSET_URL}/count-for-dashboard`);
  }

}
