import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import {
  Issue,
  IssueFilterParams,
  IssuesCount,
  IssueStatus,
  IssueUpdate, IssueUpdatePayload,
  PageableData,
  TableParams
} from '@shared/models';
import { EMPTY, expand, Observable, reduce } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  private API_URL = `/api/assets/issue`;

  constructor(private httpClient: HttpClient,
              private util: PageableUtilService) { }


  public getIssues(tableParams: TableParams, filters?: IssueFilterParams): Observable<PageableData<Issue>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<Issue>>(`${this.API_URL}`, { params });
  }

  public getIssuesCount(filters?: IssueFilterParams, noStatus = true): Observable<IssuesCount> {
    let params = this.util.buildParams(null, filters);
    params = params.delete('severities');
    if (noStatus) {
      params = params.delete('statuses');
    }
    return this.httpClient.get<IssuesCount>(`${this.API_URL}/count`, { params })
  }

  public getIssue(code: string): Observable<Issue> {
    return this.httpClient.get<Issue>(`${this.API_URL}/${code}`);
  }

  public patchIssue(code: string, payload: Partial<Issue>): Observable<Issue> {
    return this.httpClient.patch<Issue>(`${this.API_URL}/${code}`, payload);
  }

  public filteredCount(filters: IssueFilterParams): Observable<IssueStatus> {
    const params = this.util.buildParams(null, filters);
    return this.httpClient.get<IssueStatus>(`${this.API_URL}/filtered-count`, { params });
  }

  public getIssueUpdates(code: string): Observable<IssueUpdate[]> {
    const tableParams = new TableParams();
    const params = this.util.buildParams(tableParams);

    return this.httpClient.get<PageableData<IssueUpdate>>(`${this.API_URL}/${code}/update`, { params })
      .pipe(
        expand(response => {
          if (!response.last) {
            tableParams.page = tableParams.page + 1;
            return this.httpClient.get<PageableData<IssueUpdate>>(`${this.API_URL}/${code}/update`,
              { params: this.util.buildParams(tableParams) });
          } else {
            return EMPTY;
          }
        }),
        reduce((acc: IssueUpdate[], current: PageableData<IssueUpdate>) => acc.concat(current.content), [])
      );
  }

  public updateIssue(code: string, payload: Partial<IssueUpdatePayload>): Observable<IssueUpdate> {
    return this.httpClient.post<IssueUpdate>(`${this.API_URL}/${code}/update`, payload);
  }

}
