import { Audit } from './audit.model';
import { ComponentSymptom } from './components.model';

export interface Issue extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  component: string;
  inspection: string;
  status: string;
  severity: string;
  area: string;
  symptoms: ComponentSymptom[];
  images: string[];
  description: string;
  componentImage: string;
  componentName: string;
  assetName: string;
  assetCode: string;
  inspectionDate: string;
  audioFiles: string[];
}

export class IssueFilterParams {
  component?: string;
  inspection?: string;
  status?: string;
  severity?: string;
  area?: string;
  symptoms?: string[] = [];
  description?: string;
  componentImage?: string;
  componentName?: string;
  assetCode?: string;
  assetName?: string;
  inspectionDate?: string;
  asset?: string;
  severities?: string[] = [];
  statuses?: string[] = [];
  inspectionDateFrom?: string;
  inspectionDateTo?: string;
  assetNameContains?: string;
  componentNameContains?: string;
  omni?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
}

export interface IssuesCount {
  severity: IssueSeverity[];
  status: IssueStatus[];
}

export interface IssueSeverity {
  value: string;
  count: number;
  checked: boolean;
}

export interface IssueStatus {
  value: string;
  count: number;
  checked: boolean;
}

export interface IssueUpdate extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  issue: string;
  description: string;
  images: string[];
  status: string;
  lat: number;
  lng: number;
}

export interface IssueUpdatePayload {
  issue: string;
  description: string;
  images: string[];
  status: string;
  lat: number;
  lng: number;
}
