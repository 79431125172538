import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inspection, InspectionFilterParams, PageableData, TableParams } from '@shared/models';
import { Observable } from 'rxjs';
import { PageableUtilService } from "@services/util";

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  private API_URL = `/api/assets/inspection`;

  constructor(private httpClient: HttpClient,
              private util: PageableUtilService) { }

  public getInspections(tableParams: TableParams, filters?: InspectionFilterParams): Observable<PageableData<Inspection>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<Inspection>>(`${this.API_URL}`, { params });
  }

  public getInspection(code: string): Observable<Inspection> {
    return this.httpClient.get<Inspection>(`${this.API_URL}/${code}`);
  }

}
