import { KeyValue } from '@angular/common';
import { Audit } from './audit.model';

export interface InspectionType extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  name: string;
  image: string;
  componentGroups: KeyValue<string, string[]>;
  userGroups: string[];
  direction?: 'left' | 'right' | undefined;
  groups: KeyValue<string, ComponentCodeDescription[]>[];
  enabled: boolean;
}
export class InspectionTypeFilterParams {
  nameContains?: string;
  omni?: string;
}

export interface ComponentGroup {
  name: string;
  components: ComponentCodeDescription[];
}

export interface ComponentCodeDescription {
  component: string;
  description: string;
}
