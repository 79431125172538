import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetType, AssetTypeFilterParams, PageableData, TableParams } from '@shared/models';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { EMPTY, expand, map, Observable, reduce } from 'rxjs';
import { PageableUtilService } from '../util';

@Injectable({
  providedIn: 'root'
})
export class AssetTypesService {

  private API_URL = '/api/assets/asset-type';

  constructor(
    private httpClient: HttpClient,
    private util: PageableUtilService
  ) { }

  public list(tableParams: TableParams, filters?: AssetTypeFilterParams): Observable<PageableData<AssetType>> {
    const params = this.util.buildParams(tableParams, filters);

    return this.httpClient.get<PageableData<AssetType>>(`${this.API_URL}`, { params })
  }

  public create(payload: AssetType): Observable<AssetType> {
    if (payload?.code) {
      return this.httpClient.patch<AssetType>(`${this.API_URL}/${payload?.code}`, payload);
    } else {
      return this.httpClient.post<AssetType>(`${this.API_URL}`, payload);
    }
  }

  public get(code: string): Observable<AssetType> {
    return this.httpClient.get<AssetType>(`${this.API_URL}/${code}`);
  }


  public delete(code: string): Observable<AssetType> {
    return this.httpClient.delete<AssetType>(`${this.API_URL}/${code}`);
  }

  public getAllAssetTypes(tableParams: TableParams, filters?: AssetTypeFilterParams): Observable<NzSelectOptionInterface[]> {

    tableParams.sort = [];
    tableParams.sort.push({ key: 'brand', value: 'ascend' });
    tableParams.sort.push({ key: 'name', value: 'ascend' });

    const params = this.util.buildParams(tableParams, filters);

    return this.httpClient.get<PageableData<AssetType>>(`${this.API_URL}`, { params })
      .pipe(
        expand(response => {
          if (!response.last) {
            tableParams.page = tableParams.page + 1;
            return this.httpClient.get<PageableData<AssetType>>(`${this.API_URL}`,
              { params: this.util.buildParams(tableParams, filters) });
          } else {
            return EMPTY;
          }
        }),
        reduce((acc: AssetType[], current: PageableData<AssetType>) => acc.concat(current.content), []),
        map((assetTypes) =>
          assetTypes.map(assetType => ( { label: assetType.model, value: assetType.code })))
      );
  }

}
