import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import {
  AssetComponent,
  AssetComponentFilterParams,
  ComponentPayload,
  PageableData,
  TableParams
} from '@shared/models';
import { EMPTY, expand, Observable, reduce } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  private API_URL = `/api/assets/component`;

  constructor(private httpClient: HttpClient,
              private util: PageableUtilService) { }


  public getComponents(tableParams: TableParams, filters?: AssetComponentFilterParams): Observable<PageableData<AssetComponent>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<AssetComponent>>(`${this.API_URL}`, { params });
  }

  public getComponent(code: string): Observable<AssetComponent> {
    return this.httpClient.get<AssetComponent>(`${this.API_URL}/${code}`);
  }

  public saveComponent(code: string | undefined, payload: ComponentPayload): Observable<AssetComponent> {
    if (code) {
      return this.httpClient.patch<AssetComponent>(`${this.API_URL}/${code}`, payload);
    } else {
      return this.httpClient.post<AssetComponent>(`${this.API_URL}`, payload);
    }
  }

  public delete(code: string): Observable<AssetComponent> {
    return this.httpClient.delete<AssetComponent>(`${this.API_URL}/${code}`);
  }

  public getComponentList(tableParams: TableParams, filters?: AssetComponentFilterParams): Observable<AssetComponent[]> {
    const params = this.util.buildParams(tableParams, filters);

    return this.httpClient.get<PageableData<AssetComponent>>(`${this.API_URL}`, { params })
      .pipe(
        expand(response => {
          if (!response.last) {
            tableParams.page = tableParams.page + 1;
            return this.httpClient.get<PageableData<AssetComponent>>(`${this.API_URL}`,
              { params: this.util.buildParams(tableParams, filters) });
          } else {
            return EMPTY;
          }
        }),
        reduce((acc: AssetComponent[], current: PageableData<AssetComponent>) => acc.concat(current.content), []),
      );
  }

}
