import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import { InspectionType, InspectionTypeFilterParams, PageableData, TableParams } from '@shared/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InspectionTypesService {

  private readonly API_URL = '/api/assets/inspection-type';
  private readonly COMPONENT_URL = '/api/assets/component';

  refreshList = new Subject<boolean>();
  refresh$ = this.refreshList.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly util: PageableUtilService
  ) { }

  public list(tableParams: TableParams, filters?: InspectionTypeFilterParams): Observable<PageableData<InspectionType>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<InspectionType>>(`${this.API_URL}`, { params });
  }

  public get(code: string): Observable<InspectionType> {
    return this.httpClient.get<InspectionType>(`${this.API_URL}/${code}`);
  }

  public delete(code: string): Observable<InspectionType> {
    return this.httpClient.delete<InspectionType>(`${this.API_URL}/${code}`);
  }

  public create(data: InspectionType): Observable<InspectionType> {
    if (data?.code) {
      return this.httpClient.patch<InspectionType>(`${this.API_URL}/${data.code}`, data);
    } else {
      return this.httpClient.post<InspectionType>(this.API_URL, data);
    }
  }

  public refreshListObservable(refresh = true): void {
    this.refreshList.next(refresh);
  }

}
